import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94')
];

export const server_loads = [0,5,9,10];

export const dictionary = {
		"/(front)/(customer)": [~66,[3,8]],
		"/(front)/(admin)/accounts/po-import": [~16,[3,4]],
		"/(front)/(admin)/accounts/purchase-orders": [~17,[3,4]],
		"/(front)/(admin)/admin": [18,[3,4]],
		"/(front)/(admin)/admin/bagel-orders/[locationCid=locationCid]": [~19,[3,4]],
		"/(front)/(admin)/admin/bagel-orders/[locationCid=locationCid]/[year]-[month]-[day]": [~20,[3,4]],
		"/(front)/(admin)/admin/beans": [~21,[3,4]],
		"/(front)/(admin)/admin/beans/country-masl-list": [~22,[3,4]],
		"/(front)/(admin)/admin/beans/green-stock-list": [~23,[3,4]],
		"/(front)/(admin)/admin/beans/grind-record": [~24,[3,4]],
		"/(front)/(admin)/admin/beans/planning": [~25,[3,4]],
		"/(front)/(admin)/admin/daily-checklist/[location]": [~26,[3,4]],
		"/(front)/(admin)/admin/daily-checklist/[location]/[year]-[month]-[day]": [~27,[3,4]],
		"/(front)/(admin)/admin/events": [~28,[3,4]],
		"/(front)/(admin)/admin/food/shelf-life-sheet/[location]": [~29,[3,4]],
		"/(front)/(admin)/admin/hiring": [~30,[3,4]],
		"/(front)/(admin)/admin/hiring/[id]/availability": [31,[3,4,5]],
		"/(front)/(admin)/admin/hiring/[id]/info": [~32,[3,4,5]],
		"/(front)/(admin)/admin/hiring/[id]/questions": [33,[3,4,5]],
		"/(front)/(admin)/admin/jobs": [~34,[3,4]],
		"/(front)/(admin)/admin/label-printing/cups": [~35,[3,4]],
		"/(front)/(admin)/admin/lightspeed/query": [~36,[3,4]],
		"/(front)/(admin)/admin/lightspeed/webhooks": [~37,[3,4]],
		"/(front)/(admin)/admin/media-asset-list": [~38,[3,4]],
		"/(front)/(admin)/admin/online-ordering": [~39,[3,4,6]],
		"/(front)/(admin)/admin/online-ordering/test-console": [40,[3,4,6]],
		"/(front)/(admin)/admin/ordering-sheet/[location]": [~41,[3,4]],
		"/(front)/(admin)/admin/roasting": [42,[3,4]],
		"/(front)/(admin)/admin/roasting/accounts": [~43,[3,4]],
		"/(front)/(admin)/admin/roasting/order/[location=locationCid]": [~44,[3,4]],
		"/(front)/(admin)/admin/roasting/schedule": [~45,[3,4]],
		"/(front)/(admin)/admin/roasting/schedule/print": [~46,[3,4]],
		"/(front)/(admin)/admin/shopping-list/[location]": [~47,[3,4]],
		"/(front)/(admin)/admin/shortlinks": [~48,[3,4]],
		"/(front)/(admin)/admin/staff-hours-list/[location_cid]": [~49,[3,4]],
		"/(front)/(admin)/admin/subscriptions": [~50,[3,4]],
		"/(front)/(admin)/admin/table-numbers/[location_cid=locationCid]": [~51,[3,4]],
		"/(front)/(admin)/admin/users": [~52,[3,4]],
		"/(front)/(admin)/admin/users/access-levels": [53,[3,4]],
		"/(front)/(admin)/admin/users/resource-access": [54,[3,4]],
		"/(front)/(admin)/blend-guide": [~55,[3,4]],
		"/(front)/(customer)/b/v2/[qrcode]": [~67,[3,8]],
		"/(front)/(customer)/checkout": [~68,[3,8]],
		"/(front)/(customer)/checkout/pay": [69,[3,8]],
		"/(front)/(customer)/checkout/success/[id]": [~70,[3,8]],
		"/(front)/(customer)/events/august-24-bicton-cupping": [~71,[3,8]],
		"/(front)/(customer)/events/august-24-bicton-cupping/thank-you": [72,[3,8]],
		"/(front)/(customer)/faq": [73,[3,8]],
		"/(auth)/join": [~12,[2]],
		"/(front)/(admin)/links": [~56,[3,4]],
		"/(front)/(customer)/locations": [74,[3,8]],
		"/(auth)/login": [~13,[2]],
		"/(auth)/login/auth": [~14,[2]],
		"/(auth)/login/error": [15,[2]],
		"/(front)/(customer)/my-account": [~75,[3,8,9]],
		"/(front)/(customer)/my-account/orders": [~76,[3,8,9]],
		"/(front)/(customer)/my-account/orders/[id]": [~77,[3,8,9]],
		"/(front)/(customer)/news/2025-03-17/coffee-price-update-why-your-cup-costs-more": [78,[3,8]],
		"/(front)/(customer)/order": [79,[3,8,10]],
		"/(front)/(customer)/order/(products)/[category=category]": [80,[3,8,10]],
		"/(front)/(customer)/order/(products)/[category=category]/[product]": [81,[3,8,10]],
		"/(server)/pdf/roast-schedule": [~94],
		"/(front)/(customer)/privacy-policy": [82,[3,8]],
		"/(front)/(admin)/site-admin/[location]/beans": [~57,[3,4]],
		"/(front)/(admin)/site-admin/[location]/food-guide": [~58,[3,4]],
		"/(front)/(admin)/site-admin/[location]/online-products": [~59,[3,4]],
		"/(front)/(admin)/site-admin/[location]/order-history": [~60,[3,4]],
		"/(front)/(admin)/site-admin/[location]/order-history/[id]": [~61,[3,4]],
		"/(front)/(admin)/site-admin/[location]/staff-contact-list": [~62,[3,4]],
		"/(front)/(admin)/social-media/calendar": [~63,[3,4]],
		"/(front)/(admin)/social-media/calendar/[year]-[month]": [~64,[3,4]],
		"/(front)/(admin)/social-media/preview": [~65,[7]],
		"/(front)/(staff)/staff/training": [93,[3]],
		"/(front)/(customer)/terms-of-service": [84,[3,8]],
		"/(front)/(customer)/t/v2/[qrcode]": [~83,[3,8]],
		"/(front)/(customer)/visit/little-venn-evp": [85,[3,8]],
		"/(front)/(customer)/visit/venn-bicton": [86,[3,8]],
		"/(front)/(customer)/visit/venn-evp": [87,[3,8]],
		"/(front)/(customer)/work": [88,[3,8,11]],
		"/(front)/(customer)/work/assistant-cafe-manager": [~89,[3,8,11]],
		"/(front)/(customer)/work/assistant-cafe-manager/thank-you": [90,[3,8,11]],
		"/(front)/(customer)/work/coffee-crafter": [~91,[3,8,11]],
		"/(front)/(customer)/work/coffee-crafter/thank-you": [92,[3,8,11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';